.cart-div2 {
  box-shadow: 0px 1px 3px 1px rgba(116, 116, 116, 0.2);
  margin-top: 30px;
  border-radius: 8px;
}

.cart-checkoutpara {
  font-size: 14px;
  font-weight: 400;
  font-family: "lato", sans-serif;
}
.cart-checkoutfree {
  font-size: 14px;
  font-weight: 400;
  font-family: "lato", sans-serif;
  color: #00a085 !important;
}

.cart-decr {
  box-shadow: 0px 1px 3px 1px rgba(116, 116, 116, 0.2);
  padding: 11px;
  padding-bottom: 2px;
  padding-top: 0px;
  cursor: pointer;
}

.cart-qty {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

@media (max-width: 767px) {
  .cart-qty {
    margin-top: 10px !important;
  }
}

@media (min-width: 768px) {
  .cart-prodiv {
    margin-left: 15px !important;
  }
}

@media (min-width: 768px) {
  .headsummary {
    background-color: #00a085 !important;
    width: 100%;
    height: 71px;
    flex-shrink: 0;
  }
}

@media (max-width: 768px) {
  .headsummary {
    background-color: #00a085 !important;
    width: 100%;
    height: 71px;
    flex-shrink: 0;
  }
}

/* .headsummary {
    background-color: #00a085;
    width: 857px;
    height: 71px;
    flex-shrink: 0;
} */
@media (min-width: 768px) {
  .ordersummary {
    color: #fff;

    /* text-align: center; */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

@media (max-width: 768px) {
  .ordersummary {
    /* font-family: "'inter', sans-serif"; */
    font-size: 21px;
    color: white;
    padding-top: 4px;
  }
}

.qty {
  color: #5a5a5a;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 24px;
  margin-top: 5px;
}

.divider-line {
  border-bottom: none !important;
  /* background-color: white; */
}

.line {
  background-color: #c5c1c1;
  height: 1px;
  width: 100%;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .line {
    height: 1px;
    margin-top: 10px;
  }
}

.padding {
  padding: 20px;
}

.remove {
  margin-top: 14px;
}

.incr {
  margin-right: 24px;
  font-size: 20px;
  color: #000;
  /* font-weight: 600; */
  font: bold;
  cursor: pointer;
}

.decr {
  margin-right: 7px;
  font-size: 18px;
  color: #878787;
  cursor: pointer;
}
.mobcartTop {
  margin-top: 100px;
}

@media (max-width: 768px) {
  .cartTop1 {
    margin-top: 140px;
    display: none !important;
  }

  .cart-mob {
    margin-top: 50px;
  }

  .qtycol {
    padding-top: 87px !important;
  }

  .qty {
    color: #5a5a5a;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-right: 24px;
    margin-top: 5px;
  }

  .incr {
    margin-right: 24px;
    font-size: 20px;
    color: #000;
    /* font-weight: 600; */
    font: bold;
  }

  .decr {
    margin-right: 7px;
    font-size: 20px;
    color: #878787;
  }

  .cart-prodiv {
    margin-top: 21px;
    margin-left: 18px;
  }

  .imgalign {
    margin-right: 18px;
  }

  .remove {
    margin-right: 18px;
  }

  .itemqty {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    background-color: #eeeaea;
    text-align: center;
  }

  /* .itemqty1{
        padding-left: 12px;
      
    } */
}

@media (min-width: 768px) {
  .cart-mob {
    display: none !important;
  }

  .mobcartTop {
    display: none !important;
  }

  .empty {
    height: 119px;
    padding-top: 45px;
  }
}

.item-name {
  font-size: 19px;
  margin-top: 15px;
  font-family: "inter", sans-serif;
  font-weight: 500;
  color: #000;
  line-height: "normal";
  font-style: "normal";
}

.item-size {
  font-size: 16px;
  font-family: "inter", sans-serif;
  font-weight: 400;
  color: #5a5a5a;
  line-height: "normal";
  font-style: "normal";
}

.item-price {
  font-size: 19px;
  margin-top: 15px;
  font-family: "inter", sans-serif;
  font-weight: 600;
  color: #0b9547;
  line-height: "normal";
  font-style: "normal";
  text-align: center;
}

.item-pricedetails {
  font-size: 18px;
  margin-bottom: unset;
  cursor: pointer;
  font-weight: 600;
  font-family: "inter", sans-serif;
}

.item-qty {
  font-family: "inter", sans-serif;
  margin-left: 10px;
  margin-right: 20px;
  font-size: 20px;
  color: black;
}

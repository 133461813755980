.footer {
  margin-top: 20px;
}
.footer-col{
  display: flex;
  justify-content: center;
}
.footer-li {
  list-style: none;
  font-size: 13px;
  margin-top: 5px;
  color: #6e6e6e;
}
.footer-company-logo {
  background-image: url("../../Assets/Images/AL\ Badar_logo-01\ \(1\).png");
  height: 120px;
  width: 120px;
  margin: 20px;
  background-size: contain;
  background-repeat: no-repeat;
}
.footer-copyright {
  /* font-size: 13px; */
  color: #000000;
  margin-top: 30px;

  font-size: 13px;
  font-family: Lato;
  padding: 25px;
}

section {
  height: 100vh;
}

.tag {
  opacity: 0;
  transform: translate(0, 10vh);
  transition: all 1s;
}

.tag.visible {
  opacity: 1;
  transform: translate(0, 0);
}

.footerhead1 {
  color: #1a1a1a;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  /* 125.926% */
  letter-spacing: -0.405px;
  text-transform: capitalize;
}
.footerhead2 {
  color: #bebebe;
  text-decoration: none;
  cursor: pointer;

  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 141.667% */
  letter-spacing: -0.36px;
  text-transform: capitalize;
  transition: 0.2s ease-in-out;
}
.footerhead2:hover {
  color: #9c1f1f;
  transition: 0.2s ease-in-out;
}
.copyright-div {
  background-color: #cfcfcf;
  width: 100%;
}
@media (max-width: 997px) {
  .footerhead1 {
    font-size: 20px;
  }
  .footerhead2 {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .footer-copyright {
    text-align: center;
    /* background-color: #fad30c; */
    color: #33001c;
  }
  .footerhead1 {
    font-size: 25px;
  }
  .footerhead2 {
    font-size: 18px;
  }
  .footer {
    margin: 5% !important;
  }
}

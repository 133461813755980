@import "~react-image-gallery/styles/css/image-gallery.css";

@font-face {
  font-family: "CustomFontName" !important;
  src: local("CustomFontName"),
    url("./Assets/Fonts/Poppins-Regular.ttf") format("ttf");
  font-display: swap;
}

body {
  font-family: "CustomFontName" !important;
  background-color: #f3f3f3 !important;
}

@media (max-width: 575px) {
  .imagehome {
    width: 100% !important;
    /* height: 180px !important; */
    /* height: 60% !important; */
    border-radius: 10px;
  }

  .imagehome:hover {
    transform: scale(1.2);
    transition: all 0.4s ease;
  }

  .imagedeals {
    width: 100%;
    padding: 10px;
    height: 220px;
  }
}

.imagehome {
  /* padding-left: 28px; */
  width: 100%;
  height: 195px;
  border-radius: 10px;
  position: relative;
  display: inline-block;
}

.hearticon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
}

.activehearticon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
}

.imagedeals {
  width: 100%;
  padding: 10px;
  height: 220px;
}

.size-border {
  border: 1px solid #959595;
  margin-left: 20px !important;
  padding: 5px;
  height: 33px;
  margin-top: 5px;
  border-radius: 5px;
  min-width: 35px;
  cursor: pointer;
}

.size-border:hover {
  border: 1px solid #00a085 !important;
}

.size .active {
  border: 1px solid #fff;
  margin-left: 20px;
  padding: 5px;
  height: 33px;
  margin-top: 5px;
  border-radius: 5px;
  min-width: 35px;
  cursor: pointer;
  background-color: #fff;
}

.size .active .sizeText {
  margin-bottom: unset;
  color: #00a085 !important;
  font-size: 15px !important;
  text-align: center;
}

.sizeText {
  margin-bottom: unset;
  color: #959595 !important;
  font-size: 15px !important;
  text-align: center;
}

@media (max-width: 575px) {
  .spclImg .slick-slide img {
    height: 180px !important;
  }
}

.activecolor {
  border: 2px solid #00a085 !important;
  color: #00a085 !important;
  box-shadow: none;
}

.notactive {
  color: #000;
  text-align: center;
  border-radius: 2px;
  border: 2px solid #e0e0e0;
}

.spclImg .slick-slide img {
  height: 185px;
}

@media (max-width: 575px) {
  .desktopcategory {
    display: none !important;
  }

  .mobilecategory {
    display: block !important;
  }

  .mobcatdiv {
    /* margin-top: 142px; */
  }
}

.desktopcategory {
  display: block;
}

.mobilecategory {
  display: none;
}

.mob-category {
  /* background: rgb(255, 254, 222); */

  height: 108px;
  overflow: hidden;
  margin-top: 69px !important;
  box-shadow: 0 0 3px #ccc;
  position: relative;
  z-index: 1;
}

.mob-category-menu {
  width: auto;

  overflow-x: auto;
  white-space: nowrap;
  padding: 20px 2px 8px;
  line-height: 10px;
  position: relative;
  z-index: 1;
}

.mob-category .cate-list {
  display: inline-block;
  font-size: 13px;
  text-align: center;
  margin-right: 15px;
  /* padding-bottom: 50px; */
  /* margin-top: 100px; */
  position: relative;
  z-index: 1;
  /* height */
}

.mob-category .categ-img {
  height: 50px;
  overflow: hidden;
  /* margin-top: 100px; */
  position: relative;
  z-index: 1;
}

.mob-category .cate-list img {
  display: inline-block;
  height: 50px;
  /* margin-top: 100px; */
  position: relative;
  z-index: 1;
}

.totaldiv {
  margin-top: 20px;
  background-color: #f3f3f3;
}

@media (max-width: 768px) {
  .totaldiv {
    /* margin-top: -9px !important; */
    top: 0px;
    position: relative;
    z-index: 0;
  }
}

.categorytxt {
  /* text-align: center;
    margin-top: 10px;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre; */
  line-height: 40px;
  height: 40px;
  /* text-overflow: ellipsis; */
  overflow: hidden;
  white-space: pre;
  font-size: 13px;
  margin-bottom: unset;
  width: 80px;
}

@media (max-width: 575px) {
  .mobile-display {
    display: none !important;
  }

  .header2 {
    /* background-color: #fff; */
    /* margin-top: 40px !important;
        margin-left: -30px; */
    display: none !important;
  }

  .mobilebannerbtop {
    margin-top: 92px !important;
  }
}

@media (min-width: 788px) {
  .mobilebannerbtop {
    margin-top: 115px;
  }

  .heading {
    /* color: '#00a085', fontSize:'53px', fontWeight: 700, marginBottom: 'unset', fontFamily: "'lato', sans-serif" */
    color: #00a581 !important;
    font-size: 25px;

    border-bottom: 4px solid #ffae00;
    margin-bottom: unset;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    margin-bottom: 8px;
  }
}

@media (max-width: 787px) {
  .heading {
    color: #00a581 !important;
    border-bottom: 4px solid rgb(255, 174, 0);
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 8px;
    font-family: "'lato',san-serif";
  }
}

/* moonstyle */

.moonmaindiv {
  position: relative;
  background: rgb(252, 248, 248);
  flex: 1 1 0%;
  min-width: 0px;
  padding-bottom: 25px;
}

.laQvkU {
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding: 43px;
  align-content: space-between;
}

.singlediv {
  flex: 0 0 calc(25% - 7.5px);
  padding-bottom: 0px;
  margin-top: 20px;
}

@media (max-width: 575px) {
  .singlediv {
    flex: 0 0 calc(50% - 7.5px);
    padding-bottom: 0px;
    margin-top: 20px;
  }

  .msearchbar {
    padding: 10px;
  }
}

.imagediv {
  position: relative;
  background-color: transparent;
  transition: background-color 0.25s ease 0s;
  padding-bottom: 100%;
}

.ipLbIS {
  position: absolute;
  inset: 0px;
  /* display: flex; */
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.ipLbIS img:not(.zoomImage) {
  display: block;
  max-width: 100%;
}

/* .carticon {
    width: 50px;
    height: 50px;
} */

.cwhUbk {
  width: 100%;
  height: 100%;
}

@media (max-width: 575px) {
  .profileicon {
    width: 30px !important;
    height: 30px !important;
  }

  .searchdiv {
    margin-top: unset !important;
  }

  .searchbar {
    display: none !important;
  }

  .desktop-display {
    display: block !important;
  }

  .cartTop {
    margin-top: 140px !important;
  }
}

.mainLogo {
  width: 180px;
  height: 50px;
}

.searchdiv {
  position: "relative";
  margin-top: 10px;
  /* border-radius: 0px !important; */
}

.mobsearchdiv {
  position: absolute;
  z-index: 1000;
  width: 100% !important;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%);
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  right: -100%;
  height: 100%;
  top: 0;
}

.mobsearchdiv1 {
  position: absolute;
  z-index: 1000;
  width: 100% !important;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%);
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%);
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  right: 0%;
  height: 100%;
  top: 0;
}

.searchdiv1 {
  height: 100%;
}

.desktop-display {
  display: none;
  /* margin-top: 20px !important; */
}

.cartTop {
  margin-top: 140px;
  margin-bottom: 200px;
}

.mobilemenu {
  position: fixed;
  top: 0;
  bottom: 0px;
  width: 80%;
  height: 100%;
  background-color: #fff;
  z-index: 1000;
  left: -100%;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%) !important;
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05) !important;
  -webkit-box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%) !important;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}
.layout.inactive {
  pointer-events: none;
  opacity: 0.5; /* Optional: to visually indicate the layout is inactive */
}
.mobilemenu1 {
  position: fixed;
  top: 0;
  bottom: 0px;
  width: 80%;
  height: 100%;
  background-color: #fff;
  z-index: 1000;
  left: 0%;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%) !important;
  -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05) !important;
  -webkit-box-shadow: 0 0 0 1px rgb(0 0 0 / 5%), 0 1px 1px rgb(0 0 0 / 5%) !important;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}

.listtop {
  margin-top: 20px;
  list-style: none;
  padding-left: unset !important;
}

.listtop li {
  height: 65px;
  font-size: 20px;
  font-weight: 500;
  padding-left: 15px;
  position: relative;
  overflow: hidden;
  color: #979797;
}

.menulistic {
  color: #979797;
  margin-right: 12px;
  font-size: 22px;
}

/* new category */

.luxury-experiences__grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: calc((100% - 27.8px) / 3);
  border-radius: 10px;
  box-shadow: 0 0 27px 0 rgb(0 0 0 / 15%);
  overflow: hidden;
  padding: 3px;
}

.lazy-image {
  opacity: 1;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.luxury-experiences__grid-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.luxury-experiences__grid-item-title {
  text-align: center;
  font-weight: 600;
  color: #fff;
  font-size: 1.4rem;
  line-height: 1.21;
  letter-spacing: 0.84px;
  z-index: 1;
}

.luxury-experiences__grid {
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}

.luxury-experiences__grid {
  height: 588px;
  flex: 1;
}

.luxury-experiences__grid-item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.19);
  z-index: 0;
  transition: all 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/* .lazy-image:before {
    content: "";
    background-image: url(https://ui-assets-gc.thrillophilia.com/assets/placeholder-6a8d720….jpg);
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    background-size: cover;
    z-index: -1;
    display: block;
} */

.luxury-experiences__grid-item:nth-child(1) {
  height: 41.24%;
}

.luxury-experiences__grid-item:nth-child(2) {
  margin-top: -70px !important;
  height: 29%;
}

.luxury-experiences__grid-item:nth-child(3) {
  height: 43.53%;
}

.luxury-experiences__grid-item:nth-child(4) {
  margin-top: -127px !important;
  height: 26%;
}

.luxury-experiences__grid-item:nth-child(5) {
  margin-top: -202px !important;
  height: 38.09%;
}

.luxury-experiences__grid-item:nth-child(6) {
  margin-top: 10px !important;
  height: 44.38%;
}

.luxury-experiences__grid-item:nth-child(7) {
  margin-top: -151px !important;
  height: 40.36%;
}

.luxury-experiences__grid-item:nth-child(8) {
  margin-top: -245px !important;
  height: 26.36%;
}

.luxury-experiences__grid-item:nth-child(9) {
  margin-top: 10px !important;
  height: 26.36%;
}

.luxury-experiences__grid-item:nth-child(10) {
  margin-top: -65px !important;
  height: 26.36%;
}

.luxury-experiences__grid-item:nth-child(11) {
  margin-top: -160px !important;
  height: 40.36%;
}

.luxury-experiences__grid-item:nth-child(12) {
  margin-top: 10px !important;
  height: 26.36%;
}

@media (min-width: 1024px) {
  .luxury-experiences__grid-item:not(:nth-child(3n-2)) {
    margin-top: 18px;
  }
}

.luxury-experiences__grid-item {
  text-decoration: unset;
}

@media (max-width: 575px) {
  .luxury-experiences__grid-item:nth-child(1) {
    height: 41.24%;
  }

  .luxury-experiences__grid-item:nth-child(2) {
    margin-top: -44px !important;
    height: 29%;
  }

  .luxury-experiences__grid-item:nth-child(3) {
    height: 43.53%;
  }

  .luxury-experiences__grid-item:nth-child(4) {
    margin-top: -67px !important;
    height: 26%;
  }

  .luxury-experiences__grid-item:nth-child(5) {
    margin-top: -110px !important;
    height: 38.09%;
  }

  .luxury-experiences__grid-item:nth-child(6) {
    margin-top: 5px !important;
    height: 44.38%;
  }

  .luxury-experiences__grid-item:nth-child(7) {
    margin-top: -80px !important;
    height: 40.36%;
  }

  .luxury-experiences__grid-item:nth-child(8) {
    margin-top: -126px !important;
    height: 26.36%;
  }

  .luxury-experiences__grid-item:nth-child(9) {
    margin-top: 5px !important;
    height: 26.36%;
  }

  .luxury-experiences__grid-item:nth-child(10) {
    margin-top: -30px !important;
    height: 26.36%;
  }

  .luxury-experiences__grid-item:nth-child(11) {
    margin-top: -75px !important;
    height: 40.36%;
  }

  .luxury-experiences__grid-item:nth-child(12) {
    margin-top: 5px !important;
    height: 26.36%;
  }

  .luxury-experiences__grid-item-title {
    font-size: 14px !important;
  }

  .luxury-experiences__grid {
    height: 334px;
    /* margin-left: 18px; */
    flex: 1 1;
  }

  .gridadjust {
    margin-top: 177px !important;
  }

  .luxury-experiences__grid-item {
    width: calc((100% - 11.8px) / 3);
  }
}

.gridadjust {
  margin-top: 350px;
}

.img-hover-zoom img {
  transition: transform 2s, filter 1.5s ease-in-out;
  transform-origin: center center;
  filter: brightness(100%);
}

/* The Transformation */
.img-hover-zoom:hover img {
  filter: brightness(50%);
  transform: scale(1.1);
}

.detailsMargintop {
  margin-top: 210px !important;
}

@media (max-width: 575px) {
  .detailsMargintop {
    margin-top: 0px !important;
  }
}

/* border: 1px solid #d3d3d3; */
/* padding: 5px;
    height: 300px  */

.newarrival-img-border {
  background: #fff;
  box-shadow: 0px 1px 2px 1px rgba(149, 149, 149, 0.25);
  padding: 0px;
  height: 300px;
}

.main-image-container {
  /* border: 1px solid #d3d3d3; */
  padding: 5px;
  height: 300px;

  background: #fff;
  box-shadow: 0px 1px 2px 1px rgba(149, 149, 149, 0.25);
  /* max-height: 209px; */
}

.main-image-containers {
  padding: 5px;
  height: 300px;

  background: #fff;
  box-shadow: 0px 1px 2px 1px rgba(149, 149, 149, 0.25);
}

/* .comboimg:hover .comboimage {
    transform: scale(1.2);
    transition: all 0.4s ease;
} */

/* @import url('https://fonts.googleapis.com/css2?family=Palanquin+Dark:wght@500&family=Poppins:ital,wght@0,100;1,500&display=swap'); */
/* 
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300&family=Libre+Barcode+39&family=Noto+Sans+Arabic:wght@200&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;900&family=Libre+Barcode+39&family=Noto+Sans+Arabic:wght@200&display=swap");

.textfont {
  font-family: "lato", sans-serif;
}

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,400;0,700;0,900;1,300&family=Libre+Barcode+39&family=Noto+Sans+Arabic:wght@200&display=swap");

.headingfont {
  font-family: "lato", sans-serif;
}

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,400;0,700;0,900;1,300&family=Libre+Barcode+39&family=Noto+Sans+Arabic:wght@200&display=swap");

.producttext {
  font-family: "lato" sans-serif;
}

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,400;0,700;0,900;1,300&family=Libre+Barcode+39&family=Noto+Sans+Arabic:wght@200&display=swap");

.product-name {
  font-family: "lato" sans-serif;
}

.product-name {
  /* line-height: 40px; */
  height: 40px;
  /* text-overflow: ellipsis; */
  overflow: hidden;
  white-space: normal;
  font-size: 12px !important;
  margin-bottom: unset;
  font-family: "poppins" sans-serif;
  font-weight: 600;
  color: #000;
  padding: 6px 10px;
}

@media (min-width:787px) {
  .price {
    color: rgb(255, 174, 0) !important;
    font-weight: 600;
    font-size: 16px;
    /* padding: 0px 10px; */
  }
}

.price {
  color: rgb(255, 174, 0) !important;
  font-weight: 600;

}

.body {
  font-family: "lato" sans-serif;
}

@media (min-width: 576px) {
  .layout {
    margin-top: 115px;
    margin-bottom: 50px;
  }
}

@media (max-width: 575px) {
  .layout {
    margin-top: 126px;
    margin-bottom: 50px;
  }
}

@media (max-width: 991px) {
  .cartname {
    display: none !important;
  }

  .cartcount {
    display: none !important;
  }

  /* .mobileuser{
        height: 20%;
        width: 20%;
    } */
}


.smallposter-mobile {
  display: none !important;
}

.smallposter {
  display: none !important;
}

.imgfix {
  display: none !important;
}

.details-righttext1 {
  color: #00a085 !important;
}

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,400;0,900;1,300&family=Libre+Barcode+39&family=Noto+Sans+Arabic:wght@200&display=swap");

.searchdiv {
  color: #000;
}

@media (min-width: 576px) {
  .mobhead {
    display: none !important;
  }
}

@media (min-width: 981px) {
  .catname {
    text-align: center;
    margin-top: 18px;
    font-size: 15px;
    font-weight: 600;
    font-family: "lato", sans-serif;
    text-transform: capitalize;
  }
}

@media (max-width: 575px) {
  .header {
    background-color: #f3f3f3;
  }
}

@media (max-width:484) {
  .price {
    font-size: 15px;
  }
}

@media (max-width: 787px) {
  .product-name {
    color: #000;

    font-family: Lato;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    /* line-height: 16px; */
    /* 123.077% */
    letter-spacing: -0.195px;
    text-transform: capitalize;
    padding: 0px 10px;
  }



  /* .price {
    padding: 10px 10px;
    font-size: 15px;
  } */

  .catname {
    color: #000;
    text-align: center;
    font-family: Lato;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 123.077% */
    letter-spacing: -0.195px;
    text-transform: capitalize;
  }
}

.checkoutbtn {
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  border-color: #00a085 !important;
  background-color: #00a085 !important;
  color: #fff;
  margin-top: 10px;
  box-shadow: none;
  height: 36px;
  border-radius: 10px;
}

.mob-checkoutbtn {
  font-size: 13px;
  font-weight: 400;
  width: 100%;
  border-color: #00a085 !important;
  background-color: #00a085 !important;
  color: #fff;
  box-shadow: none;
  height: 36px;
  border-radius: 10px;
}

.continueshoppingbtn {
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  border-color: #00a085 !important;
  background-color: none;
  color: #fff;
  margin-top: 10px;
  box-shadow: none;
}

.mob-continueshoppingbtn {
  font-size: 13px;
  font-weight: 400;
  width: 100%;
  border-color: #00a085 !important;
  background-color: none;
  color: #00a085 !important;
  margin-top: 20px;
  box-shadow: none;
  text-align: center;
  font-family: "lato", sans-serif;
}

.btn-success {
  background-color: #00a085 !important;
}

.mob-name {
  font-size: 15px;
  padding-top: 8px;
  font-family: "inter", sans-serif;
  font-weight: 500;
  color: #000;
  line-height: normal;
  font-style: normal;
}

.mob-size {
  color: #5a5a5a;
  font-size: 13px;
  font-family: "inter", sans-serif;
  font-weight: 400;
  line-height: normal;
  font-style: normal;
  padding-top: 8px;
}

.mob-price {
  color: #0b9547;
  font-size: 15px;
  font-family: "inter" sans-serif;
  font-weight: 600;
  line-height: normal;
  font-style: normal;
  padding-top: 8px;
}

.guestlogin-head {
  box-shadow: none;
  width: 100%;
  border: none !important;
  font-size: 14px;
  background-color: #00a597 !important;
}

.guestlogin-home {
  width: 100%;
  font-size: 14px;
  color: #00a597 !important;
  cursor: pointer;
  text-align: center;
  font-weight: 500;
}

.guestlogin-submit {
  width: 100%;
  background-color: #00a597 !important;
  border: none !important;
  box-shadow: none;
}

.login-head {
  background-color: #00a597 !important;
  width: 100%;
  border: none !important;
  box-shadow: none;
  font-size: 15px;
}

.login-text {
  color: #00a597 !important;
  cursor: pointer;
}

.login-back {
  color: #00a597 !important;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}

.backtohome {
  font-weight: 400;
  font-family: "lato", sans-serif;
  font-size: 14px;
  border: none !important;
  background-color: #00a597 !important;
  color: #fff;
  padding: 5px;
  border-radius: 10px;
}

/* @media(min-width:767px){ */
.maparea {
  width: 100% !important;
  height: 100% !important;
  min-height: 250px;
  padding-top: 15px;
}

/* } */
/*........................ image and icon section.......................................... */

.cart-icon {
  width: 20px;
  height: 20px;
}

@media (min-width: 576px) {
  .company-logo {
    /* background-image: url('./Assets/Images/snap\ qatar\ logo\ \ 1.png'); */
    background-image: url("./Assets/Images/AL\ Badar_logo-01\ \(1\).png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 80px;
    width: 118px;
  }

  .company-logo-div {
    height: 65px;
    width: 176px;
  }

  .profileicon {
    background-color: #00a085;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 50px;
    height: 50px;
  }

  .profileicon-div {
    width: 42px;
    height: 42px;
    margin-left: 20px;
    margin-top: 27px;
  }

  .carticon {
    background-color: #00a085;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin-top: 10px;
  }

  .customercare {
    background-image: url("./Assets/Images/Group\ 250\ 1\ \(1\).svg");
    background-size: cover;
    margin-top: 1px;
    margin-right: 10px;
    height: 20px;
    width: 20px;
    color: #fad30c;
  }

  .delivery {
    background-image: url("./Assets/Images/Group\ 232.svg");
    background-size: cover;
    margin-top: 1px;
    margin-right: 10px;
    height: 20px;
    width: 20px;
    color: #fad30c;
  }

  .login-company-logo {
    /* background-image: url('./Assets/Images/snap\ qatar\ logo\ \ 1.png'); */
    background-image: url("./Assets/Images/AL\ Badar_logo-01\ \(1\).png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    height: 88px;
    width: 106px;
  }
}

@media (max-width: 575px) {
  .company-logo {
    background-image: url("./Assets/Images/AL\ Badar_logo-01\ \(1\).png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 41px;
    width: 73px;
    position: relative;
  }
  .company-logo-white {
    background-image: url("./Assets/Images/badar-white-logo.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 41px;
    width: 73px;
    position: relative;
  }

  .cart-icon {
    width: 15px;
    height: 15px;
  }

  .company-logo-div {
    height: 31px;
    width: 84px;
  }

  .profileicon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00a085;
    border-radius: 30px;
    height: 30px;
    width: 30px;
  }

  .carticon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00a085;
    border-radius: 30px;
    width: 30px !important;
    height: 30px !important;
  }

  .menu {
    /* background-image: url("./Assets/Images/Group\ 268.svg"); */
    /* margin-top: 12px;
    height: 19px;
    left: 0;

    top: 0;
    width: 17px; */
  }

  .login-company-logo {
    background-image: url("./Assets/Images/AL\ Badar_logo-01\ \(1\).png");
    background-size: cover;
    height: 100px;
    width: 100px;
  }
}


@media(min-width:767px) {
  .addCol2 {
    display: none !important;
  }

  .add-atag {
    display: none !important;
  }
}

@media(max-width:767px) {
  .addCol {
    display: none !important;
  }

  .add-atag2 {
    display: none !important;
  }
}

/*.............................bottom bar and header .........................................*/

@media (min-width:576px) {
  .bottombar-main {
    display: none !important;
  }
}

@media (max-width:575px) {
  .bottombar-main {
    display: block !important;


  }

  .bottombar {
    display: block;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 20PX;
  }

  .bottombar-icons {
    height: 25px;
    width: 25px;
  }

  .bottombar-col {
    display: flex;
    justify-content: center;
  }

  .copyright-div {
    padding-bottom: 60px;
  }

  .header {
    background-color: #0C7424;
    border-radius: 0px 0px 24px 24px;
  }
  .homewishdiv{
    display: flex;
    justify-content: end;
  }
  .homewishicon{
    color: #fff;
    height: 22px;
    width: 22px;
  }

  .dataResult{
    width: 87% !important;
  }
} 
.mobileserachinp{
  background-color: #7FBD98;
  border-radius: 20px;
}
.homemenuicon{
  height: 27px;
  width: 27px;
  color: #fff;
}

/*........................ image section.......................................... */

.catorycol {
  height: 400px;
  margin: 20px;
  background-color: red;
}

.image-gallery-icon {
  width: 100%;
  height: 100%;
  color: transparent;
}

.image-wrapper {
  overflow: hidden;
  /* display: inline-block; */
  position: relative;
}

.searchItem {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

#video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.video-banner {
  width: 100%;
  height: 70vh;
}

/* changes to the product view */
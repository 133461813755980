.image-gallery-svg {
    width: 20px !important;
}

.image-gallery-image {
    height: 300px !important;
}

.fullscreen .image-gallery-image {
    height: unset !important;
}

.image-gallery-thumbnail-image {
    height: 70px !important;
}


.off-border {
    background-color: #d4ffe5;
    padding: 4px;
    margin-left: 20px;
    height: 25px;
    margin-top: 5px;
    border-radius: 5px;
}

.details-imgtext {
    font-weight: 600;
    margin-top: 30px;
}

.wishbtn {
    margin-top: 10px !important;
    height: 33px !important;
    font-size: 14px !important;
    /* font-weight: bold !important; */
    box-shadow: none !important;
    width: 100% !important;
    color: #00a085 !important;
    background-color: #fff;
    border-color: #00a085 !important;
}

@media (min-width: 768px) {
    .details-imgtext {
        display: none !important;
    }
}

@media(max-width: 768px) {
    .details-righttext {
        display: none !important;
    }

    .details-imgtext {
        margin-top: 70px !important;
    }

    .details-righttext1 {
        margin-top: 30px !important;
    }

    .details-price {
        margin-top: 5px !important;
    }
}

@media(min-width: 768px) and (max-width: 991px) {
    .details-righttext {
        margin-top: 50px;
    }
}

@media (max-width: 420px) {
    .buttonStyledel {
        justify-content: center !important;
        align-items: center !important;
        position: fixed !important;
        bottom: 7px !important;
        width: 93% !important;
        z-index: 1000 !important;
        color: #fff;
    }
}

.prdalign {
    margin-top: 245px;
}

@media(max-width:786px) {
    .home-cartbtn {
        display: block !important;
    }
}

.prd1-currency {
    color: #00a085;
    font-size: 14px;
    font-weight: 600;
}

.prd2-currency {
    color: #00a085;
    font-size: 18px;
    font-weight: bold;
}



.image-container1 {}

.full-screen-image1 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #000;
    object-fit: contain;
    white-space: nowrap;

}

.image-container1 {
    display: flex;
    justify-content: center;
}

.full-screenable-image1 {
    max-height: calc(100vh - 80px) !important;
    width: 100%;
}


.full-screenable-image1:-webkit-full-screen {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.full-screenable-image1:-moz-full-screen {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.full-screenable-image1:-ms-fullscreen {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.full-screenable-image1:fullscreen {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.header {
  position: fixed;
  width: 100%;
  z-index: 1000;
  margin: 0;
  top: 0;
  left: 0;
  transition: all 300ms ease-in-out;
}

.hidden {
  transform: translateY(-100%);
  transition: all 300ms ease-in-out;
}

.header1 {
  display: none;
  background-color: #00a085 !important;
  /* padding: 10px; */
  padding-top: 20px;
  padding-bottom: 20px;
}

.header2 {
  background-color: #f3f3f3;
  padding: 10px;
}

.header1-para {
  font-size: 14px;
  color: #fff;
  font-weight: 600;
}
.header2-para {
  font-size: 14px;
  color: #fff;
  font-weight: 600;
}

.header-textinput {
  margin-top: "70%";
  font-size: 14px;
  width: 100%;
  box-shadow: none !important;
  padding-right: 40px;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;0,900;1,400&display=swap");

.header-textinput,
.header1-para .header2-para {
  font-family: "lato", sans-serif;
}

/* .profilebox{
    position: absolute;
    right: 160px;
    z-index: 1000;
    padding: 15px;
    padding-bottom: 1px;
    width: 220px;
    margin-top: 50px;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    border-radius: 6px;
    background-color: #ffffff;
    -webkit-box-shadow:  0px 1px 3px 1px rgba(116, 116, 116, 0.2);
    box-shadow:  0px 1px 3px 1px rgba(116, 116, 116, 0.2);
  } */

@media (max-width: 991px) {
  .header1 {
    display: none;
    /* padding-bottom: 2px */
  }

  .header2 {
    padding-bottom: 10px;
  }

  .logo {
    height: 31px;
    position: relative;
    width: 84px;
  }

  .cartcountdiv {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .mobhead {
    display: none !important;
  }
}

@media (max-width: 575px) {
  .mobhead {
    padding-top: 17px;
    /* margin-top: 13px; */
    margin-left: 25px;
    margin-right: 25px;
    padding-bottom: 19px;
    /* margin-bottom: 16px; */
  }

  .msearchbar {
    padding-top: 10px;
  }

  .textfont1 {
    width: 105px;
    color: #000;
    text-decoration: none;
    font-size: 9px;
    padding-left: 10px;
    padding-top: 10px;
    margin-bottom: unset;
  }
}

@media (min-width: 992px) {
  .responsive-cart {
    /* margin-top: 22px; */
  }

  .logocol {
    padding-top: 10px;
  }

  .cartcountdiv {
    margin-top: 2px;
    margin-left: -29px;
  }

  .textfont1 {
    /* fontSize: 16,
                          fontWeight: "bold",
                          marginTop: "37px",
                          color: "#000",
                          fontSize: "20px",
                          fontWeight: 600,
                          paddingLeft: "10px", */
    /* font-size: 16px; */
    font-weight: bold;
    margin-top: 37px;
    color: #000;
    font-size: 20px;
    font-weight: 600;
    padding-left: 10px;
  }

  /* .responsive-cart1{
        margin-right: 100px;
    } */
}

/* @media (max-width: 991px) {
  .responsive-cart {
    margin-top: 5px; 
  }
} */
/* .responsive-cart {
  margin-top: 22px;

} */

.dataResult {
  width: 100%;
  max-height: 350px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
  position: absolute;
  z-index: 5;
}

.dataResult::-webkit-scrollbar {
  display: none;
}

.searchItem {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

#clearBtn {
  cursor: pointer;
}

.home-category {
  width: 200px;
  max-height: 350px;
  background-color: white;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  border: 1px solid #d3d3d3;
  overflow: hidden;
  overflow-y: auto;
  position: absolute;
  z-index: 999;
  border-bottom: 5px solid #00a085;
}

.home-subcategory2 {
  width: 200px;
  background-color: white;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */

  overflow: hidden;
  overflow-y: auto;
  position: absolute;
  z-index: 999;

  transition: height 0.4s ease;
  -moz-transition: height 0.4s ease;
  -o-transition: height 0.4s ease;
  -ms-transition: height 0.4s ease;
  -webkit-transition: height 0.4s ease;
  height: 0px;
  margin-left: 200px;
}

.catopen2 {
  width: 200px;
  background-color: white;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  border: 1px solid #d3d3d3;
  overflow: hidden;
  overflow-y: auto;
  position: absolute;
  z-index: 999;
  border-bottom: 5px solid #00a085;
  transition: height 0.4s ease;
  -moz-transition: height 0.4s ease;
  -o-transition: height 0.4s ease;
  -ms-transition: height 0.4s ease;
  -webkit-transition: height 0.4s ease;
  height: unset !important;
  margin-left: 200px;
}

.home-category2 {
  width: 200px;
  background-color: white;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  overflow: hidden;
  overflow-y: auto;
  position: absolute;
  z-index: 999;
  transition: height 0.4s ease;
  -moz-transition: height 0.4s ease;
  -o-transition: height 0.4s ease;
  -ms-transition: height 0.4s ease;
  -webkit-transition: height 0.4s ease;
  height: 0px;
}

.catopen1 {
  width: 200px;
  background-color: white;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  border: 1px solid #d3d3d3;
  overflow: hidden;
  overflow-y: auto;
  position: absolute;
  z-index: 999;
  border-bottom: 5px solid #00a085;
  transition: height 0.4s ease;
  -moz-transition: height 0.4s ease;
  -o-transition: height 0.4s ease;
  -ms-transition: height 0.4s ease;
  -webkit-transition: height 0.4s ease;
  height: unset !important;
}

.cate-list {
  list-style-type: none;
  padding: 0 !important;
}

li {
  cursor: pointer;
}

@media (max-width: 450px) {
  .home-category2 {
    position: "absolute" !important;
    left: 0 !important;
    top: 80px !important;
  }
  .textfont1 {
    display: none;
  }
}

.premium-btn {
  width: 150px;
  font-size: 18px;
  padding: 3px;
  text-transform: uppercase;
  font-family: "lato", sans-serif;
  background-color: rgb(255, 196, 0);
  color: white;

  border-radius: 8px;
  float: right;
  transition: 0.4s ease-in-out;
}

.premium-btn:hover {
  transform: scale(1.1);
  transition: 0.4s ease-in-out;
}

/* .header2-para {
  position: relative;
  display: inline-block;
  border-bottom: 1px ;
}

.header2-para .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: rgb(255, 196, 0);;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  top: 100%;
  left: 50%;
  margin-left: -60px;
 
  position: absolute;
  z-index: 1;
}

.header2-para:hover .tooltiptext {
  visibility: visible;
} */

/* .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown-content a:hover {background-color: #ddd;}

.profileicon:hover .dropdown-content {display: block !important;}

.profileicon:hover .profileicon {background-color: #3e8e41;} */

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 10px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
  border-radius: 10px;
}

.profileicon:hover .dropdown-content {
  display: block !important;
}

.profileicon:hover {
  background-color: #00a581;
}
@media (max-width:900px){
  .dropdown-content{
    display: none !important;
  }
}
@media (min-width:900px){
  .dropdown-content{
    margin-top: 305px;
  }
}


.dropdown-content1 {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 10px;
}

.dropdown-content1 a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content1 a:hover {
  background-color: #ddd;
  border-radius: 10px;
}

.cursorusername:hover .dropdown-content1 {
  display: block !important;
  border-radius: 10px;
}

.cursorusername:hover {
 color: #00a581;
}
@media (max-width:900px){
  .dropdown-content1{
    display: none !important;
  }
}
@media (min-width:900px){
  .dropdown-content1{
    top: 94px;
  }
}



.dropdown-content2 {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  border-radius: 10px;
}

.dropdown-content2 a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content2 a:hover {
  background-color: #ddd;
  border-radius: 10px;
}

.cursorusername1:hover .dropdown-content2 {
  display: block !important;
  border-radius: 10px;
}

.cursorusername1:hover {
 color: #00a581;
}
@media (max-width:900px){
  .dropdown-content2{
    display: none !important;
  }
}
@media (min-width:900px){
  .dropdown-content2{
    top: 94px;
  }
}

/* .carticonhover{
  display: none;
  background-color: #f1f1f1;
  width: auto;
  margin-top: 10px;
  box-shadow: 0px 8px 16px 0px #9f9696;
  z-index: 1;
  position: absolute;
  border-radius: 10px;
}
.carticonhoverdiv{
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 10px;
  border-bottom: 1px solid #e3cece;
}
.carthovername{
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  font-family: "lato", sans-serif;
  font-size: 13px;
  font-weight: 600;
}
.carthoverimg{
  height: 50px;
  width: 50px;
  border-radius: 12px;
}



.carthovermaindiv:hover .carticonhover{
display: block !important;
border-radius: 12px;
} */

.carticonhover {
  display: none;
  background-color: #f1f1f1;
  width: auto;
  margin-top: 1px;
  box-shadow: 0px 8px 16px 0px #9f9696;
  z-index: 1;
  position: absolute;
  border-radius: 10px;
}

.carticonhoverdiv {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 10px;
  border-bottom: 1px solid #e3cece;
}

.carthovername {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  font-family: "lato", sans-serif;
  font-size: 11px;
  font-weight: 600;
}

.carthoverimg {
  height: 50px;
  width: 50px;
  border-radius: 12px;
}

.carthovermaindiv:hover .carticonhover {
  display: block !important;
  border-radius: 12px;
}
.carticonhoverdiv:hover{
  background-color: #ddd;
}
.checkout-div{
    box-shadow: 0px 1px 3px 1px rgba(116, 116, 116, 0.2);
    margin-top: 10px;
    border-radius: 8px;
}
.check-checkoutpara{
    font-size: 14px; 
    font-weight: 400;
    font-family: 'lato',sans-serif;
}
.check-checkoutfree{
    font-size: 14px; 
    font-weight: 400;
    font-family: 'lato',sans-serif;
    color: #00a085 !important;
}

@media(min-width: 768px){
    .checkout-prodiv{
        margin-left: 15px !important;
    }
}
@media(max-width: 575px){
    .checkout-maindiv{
        margin-top: 70px !important;
    }
}
/* . fontSize: 15, marginBottom: 'unset', marginTop: 15 */
.item-cname{
    font-size: 15px;
    margin-bottom: unset;
    margin-top: 15px;
}
.item-csize{
    color: #a9a9a9;
    font-size: 13px;
}
.item-cprice{
    color:  #00a085;
    font-size: 16px;
    margin-top: 20px;
}
.proceed-btn{
    background-color: #00a085 !important;
    width: 100%;
    font-size: 13px;
    font-family: 'lato',sans-serif;
    font-weight: 400;
}
.addcolor{
    background: #2b76ff;
    padding: 2px;
    color: #0a0a0a;
    border-radius: 2px;
}
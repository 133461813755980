.slick-prev:before, .slick-next:before {
    color: #000!important;
}

.slick-slide  div {
    outline: unset!important;
    cursor: pointer;
  }

  .slick-arrow{
      position: absolute !important;
      top:  145px !important;
  }
  @media (min-width: 601px) and (max-width: 767px){
    .slick-arrow{
        top:  108px !important;
    }
  }
  @media (min-width: 992px) and (max-width: 1024px){
    .slick-arrow{
        top:  200px !important;
    }
  }

  @media (max-width: 575px){
      .product-leftdiv{
          margin-top: 30px !important;
          margin-left: 15px !important;
      }
      .slick-prev {
        position: absolute !important;
        top: -23px !important;
        right: 70px !important;
        left: unset !important;
      }
      .slick-next {
        position: absolute !important;
        top: -23px !important;
        right: 30px !important;
      }
  }
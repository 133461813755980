.subcats {
    margin-top: 18px;
    display: flex;
    justify-content: center;
}

.subcat-imgs {
    width: "100%";
    height: 180px;
    border-radius: 55px;

}

@media(max-width:768px) {
    .subcat-imgs {
        width: "80%";
        height: 90px;
        border-radius: 27px;
    }
    .catborders{
        margin-top: 130px !important; 
    }
}
.catborders{
    margin-top: 185px;
}
.bottom-banner {
  display: none !important;
}

/* @media(min-width: 768px) and (max-width: 991px) {
    .bottom-banner {
        height: 150px !important;
    }
}

@media(max-width: 767px) {
    .bottom-banner {
        height: 104px !important;
    }
} */

.dealday {
  width: 100%;
  height: 220px;
  margin-top: 20px;
}

@media (max-width: 991px) {
  .dealday {
    height: 550px !important;
  }

  .timer {
    margin-top: 30px !important;
  }
}

.see-all {
  border-color: #ffffff !important;
  box-shadow: none !important;
  font-size: 12px !important;
  height: 35px !important;
  font-weight: bold !important;
}

.timer-div {
  border: 4px solid #ffffff !important;
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  padding: 20px;
  padding-bottom: 1px;
  text-align: center;
}

.home-catpara {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 100%;
  font-size: 13px;
  margin-bottom: unset;
}

@media (max-width: 991px) {
  .home-catpara {
    font-size: 12px !important;
  }

  .img-border {
    height: 289px !important;
  }
}

@media (max-width: 787px) {
  .newarrival-img-border {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 1px 2px 1px rgba(149, 149, 149, 0.25);
    padding: 0px;
    height: 300px !important;
    /* max-height: 209px;
        max-width:193px */
  }
}

@media (min-width: 576px) and (max-width: 991px) {
  .home-catpara {
    font-size: 12px !important;
  }

  .img-border {
    height: 289px !important;
  }

  .newarrival-img-border {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 1px 2px 1px rgba(149, 149, 149, 0.25);
    padding: 5px;
    height: 300px !important;
  }
}

.spacereduce {
  padding-right: 5px !important;
  padding-left: 5px !important;
  margin-top: 10px !important;
}

.spacereducer1 {
  padding-right: 15px !important;
  padding-left: 15px !important;
  margin-top: 10px !important;
}

@media (max-width: 575px) {
  .spacereducer {
    /* margin-top: -140px !important; */
  }
}

/* .product-name {
    line-height: 40px;
    height: 40px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
    font-size: 18px;
    margin-bottom: unset;
    font-family: 'poppins' sans-serif;
    font-weight: 600;
    color: #000;

} */

.catborder {
  /* border: 2px solid #00a085; */
  /* padding: 5px;
    padding-top: 0px; */
  font-family: "popins" sans-serif;
  font-family: 500;
}

.cart-content {
  box-shadow: 0 0 3px rgb(60 72 88 / 15%);
  width: 400px;
  min-height: 200px;
  max-height: 600px;
  position: fixed;
  right: 0px;
  top: 0px;
  background-color: #ffffff !important;
  /* overflow-y: auto;
    overflow-x: hidden; */
  padding: 15px;
  z-index: 1000 !important;
}

.innerdiv {
  overflow-y: auto;
  overflow-x: hidden;
  height: 400px;
}

@media (max-width: 500px) {
  .cart-content {
    width: 100%;
    min-height: 250px !important;
    max-height: 300px !important;
  }

  .innerdiv {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100px;
  }

  /* .smallposter{
        width: 100%;
         height: 50%;
          display: flex;
        justify-content: center;
   
    } */
}

/* .fiximg{
    width:'100%'
} */
/* 
@media (max-width: 768px) {
    .smallposter {
        grid-template-columns: 1fr; /* Change to single column layout on mobile */
/* }
    .smallposter > div {
        padding: 10px; /* Add padding between items on mobile */
/* }
    .smallposter img {
        width: 100%; /* Ensure images take full width */
/* height: auto; /* Maintain aspect ratio */
/* }
}  */

/* Default styling for desktop */

.smallposter-mobile {
  display: none;
}

/* Mobile styling */
@media (max-width: 768px) {
  .smallposter img {
    display: none !important;
  }

  .smallposter {
    max-height: 1px !important;
  }

  .smallposter-mobile {
    display: grid;
    text-align: center;
    /* padding: 10px;  */
  }

  .smallposter-mobile img {
    padding: 10px;
    width: 100%;
    height: auto;
  }

  /* .smallposter-mobile {
        display: block; 
    } */
}

.imgfix {
  min-height: 500px;
}

.imgfixmobile {
  display: none !important;
}

@media (max-width: 768px) {
  .imgfixmobile {
    padding-top: 250px;
    background-attachment: fixed;
    background-size: cover;
    margin-top: 150px;
    margin-bottom: 10px;
    min-height: 300px;
    display: block !important;
    /* background-color: red; */
  }

  .imgfix {
    display: none !important;
  }

  /* .imgfix{
        display: none !important;
    } */
}

/* For iPhones in portrait mode */
@media only screen and (max-device-width: 414px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
  .imgfixmobile {
    display: none !important;
  }
}

/* For iPhones in landscape mode */
@media only screen and (max-device-width: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
  .imgfixmobile {
    display: none !important;
  }
}

/* .imgfix{
    display: none !important;
} */

/* .imgfixmobile {
display: none !important;
} */

/* .imgfix{
    display: none;
} */

/* .hover1 figure {
    position: relative;
}

.hover1 figure::before {
    position: absolute;
    top: 0;
    left: -52%;
    z-index: 2;
    display: block;
    content: '';
    width: 50%;
    height: 186px;
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(250, 8, 8, 0.3) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(187, 20, 20, 0.3) 100%);
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg);
}

.hover1 figure:hover::before {
    -webkit-animation: shine .85s;
    animation: shine .85s;
}

@-webkit-keyframes shine {
    100% {
        left: 105%;
    }
}

@keyframes shine {
    100% {
        left: 105%;
    }
}


.hover2 figure {
    position: relative;
}

.hover2 figure::before {
    position: absolute;
    top: 0;
    left: -52%;
    z-index: 2;
    display: block;
    content: '';
    width: 50%;
    height: 186px;
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(250, 8, 8, 0.3) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(187, 20, 20, 0.3) 100%);
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg);
}

.hover2 figure:hover::before {
    -webkit-animation: shine .85s;
    animation: shine .85s;
}

@-webkit-keyframes shine {
    100% {
        left: 105%;
    }
}

@keyframes shine {
    100% {
        left: 105%;
    }
} */

/* .style={{ width: '100%', height: 180, borderRadius: 55, marginTop: 18 }} */
.subcat {
  margin-top: 18px;
}

.subcat-img {
  width: "100%";
  height: 180px;
  border-radius: 55px;
}

@media (max-width: 768px) {
  .subcat-img {
    width: "80%";
    height: 90px;
    border-radius: 27px;
  }

  .dropdown {
    position: relative;
    display: inline-block;
    /* style={{ color: '#00a085', fontSize: 13, fontWeight: 600, fontFamily: "'lato', sans-serif",paddingTop:'30px' }} */
    color: #00a085;
    font-size: 13px;
    font-weight: 600;
  }
}

.dropbtn {
  background-color: #f3f3f3;
  color: #4caf50;
  /* padding: 16px; */
  font-size: 16px;
  border: none !important;
  cursor: pointer;
  margin-right: 0px;
}

@media (min-width: 788px) {
  .dropdown {
    position: relative;
    display: inline-block;
    /* style={{ color: '#00a085', fontSize: 13, fontWeight: 600, fontFamily: "'lato', sans-serif",paddingTop:'30px' }} */
    color: #00a085;
    font-size: 13px;
    font-weight: 600;
    padding-top: 30px;
  }
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  cursor: pointer;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #f3f3f3;
}

@media (max-width: 575px) {
  .topselling {
    /* margin-top: 170px !important; */
  }
}

@media (max-width: 575px) {
  .categoryimg {
    /* margin-top: 170px !important; */
    /* padding-top: 120px; */
    /* padding: 5px; */
  }
}

.categoryimg {
  margin-top: 10px;
}

@media (max-width: 575px) {
  .deals {
    /* margin-top: -170px !important; */
  }
}

.imp {
  padding-top: -10px !important;
}

.deliveryclick-font {
  color: #00a085;
}

.load-more-btn1 {
  width: 110px;

  white-space: nowrap;
  border: 2px solid #00a597;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 14px;
  font-family: sans-serif;
  text-transform: capitalize;

  color: #00a597;
  transition: 0.3s ease-in-out;
}
.load-more-btn1:hover {
  background-color: #00ecd9;
  color: #ffffff;
  border: 2px solid #00ecd9;
  transition: 0.3s ease-in-out;
}
.button-div1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  margin-top: 14px;
}

#video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.video-banner {
  width: 100%;
  height: 70vh;
}

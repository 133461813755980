.slick-prev{
    display: none !important;
}
.slick-next{
    display: none !important;
}
.arrival-para1{
    font-size: 15px;
    font-weight: 500;
    margin-bottom: unset;
    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
   -webkit-box-orient: vertical;
}
.arrival-para2{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: unset;
}
.arrival-para3{
    font-size: 12px;
    font-weight: bold;
    margin-bottom: unset;
}
.slick-dots li {
    margin: unset !important;
}
.home-cartbtn{
    width: 100% !important;
    color: #00a085 !important;
    /* font-weight: bold !important; */
    font-size: 14px !important;
    border: none !important;
    box-shadow: none !important;
}
/* .imagehome:hover {
    transform: scale(1.2);
    transition: all 0.4s ease;
} */
/* .productimg:hover {
    transform: scale(1.2);
    transition: all 0.4s ease;
} */
@media(min-width:787px){
    .newarrivaldiv{
/* margin-top: 9%; */
/* padding: 10px; */

    }
}



::-webkit-scrollbar {
    width: 0px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
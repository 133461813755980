.profilebox {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background-color: #ffffff !important;
  font-family: "poppins" sans-serif;
}

@media (max-width: 767px) {
  .my-profilediv {
    margin-top: 30px !important;
  }
}

.shadow12 {
  box-shadow: none !important;
}
.change-password {
  border-color: #00b487 !important;
  box-shadow: none;
  margin-top: 20px;
  background-color: #00b487 !important;
  color: #ffffff !important;
  font-family: "lato", sans-serif;
  font-weight: 400;
}
.update-profile {
  background-color: #00b487 !important;
  box-shadow: none;
  border: none !important ;
  font-size: 14px;
  font-family: "lato", sans-serif;
  font-weight: 400;
}
.update-address {
  background-color: #00b487 !important;
  box-shadow: none;
  border: none !important ;
  font-size: 14px;
  font-family: "lato", sans-serif;
  font-weight: 400;
}
.save-password {
  background-color: #00b487 !important;
  box-shadow: none;
  border: none !important ;
  font-size: 14px;
  font-family: "lato", sans-serif;
  font-weight: 500;
  margin-top: 20px;
}

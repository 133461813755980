.myordersdiv{
    border: 1px solid #d3d3d3;
    padding: 15px;
    padding-bottom: 1px;
    margin-bottom: 20px;
}

@media (max-width: 450px){
    .order-img{
        width: 40% !important;
    }
    .order-paradiv{
        margin-top: 20px;
    }
}

@media(min-width: 451px) and (max-width: 767px){
    .order-img{
        width: 30% !important;
    }
    .order-paradiv{
        margin-top: 20px;
    }
}

@media (min-width:768px){
    .order-img{
        height: 100px;
        width: 100%;
    }
}

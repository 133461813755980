.image-gallery-svg {
    width: 20px !important;
}

.image-gallery-image {
    height: 300px !important;
}

.fullscreen .image-gallery-image {
    height: unset !important;
}

.image-gallery-thumbnail-image {
    height: 70px !important;
}

.off-border {
    background-color: #d4ffe5;
    padding: 4px;
    margin-left: 20px;
    height: 25px;
    margin-top: 5px;
    border-radius: 5px;
}

/* .details-imgtext{
    margin-top: 30px ;
} */

.wishbtn {
    margin-top: 10px !important;
    height: 33px !important;
    font-size: 14px !important;
    /* font-weight: bold !important; */
    box-shadow: none !important;
    width: 100% !important;
    background-color: #fff !important;
    color: #00a085 !important;
    border-color: #00a085 !important;
    font-family: 'lato',sans-serif;
    font-weight: 600;
}
.home-cartbtn{
    width: 100% !important;
    color: #00a085 !important;
    /* font-weight: bold !important; */
    font-size: 14px !important;
    border: none !important;
    box-shadow: none !important;
    font-family: 'lato',sans-serif;
    font-weight: 600;
}
.item-currency{
    color: #00a085;
    font-weight: bold;
    font-family: 'lato',sans-serif;
}
.p-name{
    padding-bottom: 7px;
    font-family: 'lato',sans-serif;
    font-weight: 600;
}
.p-code{
    padding-bottom: 5px;
    font-size: 13px;
font-family: 'lato',sans-serif;
}
/* @media (min-width: 768px){
    .cdetails{
        display: none !important;
        margin-top: -50px !important;
        padding-top: -50px !important;
    }
} */
@media(max-width: 768px) {
    .details-righttext {
        display: none !important;
    }

    .details-imgtext {
        margin-top: 70px !important;
    }

    .details-righttext1 {
        margin-top: 30px !important;
    }

    .details-price {
        margin-top: 5px !important;
    }
}

@media(min-width: 768px) and (max-width: 991px) {
    .details-righttext {
        margin-top: 50px;
    }
}

@media (max-width: 420px) {
    .buttonStyledel {
        justify-content: center !important;
        align-items: center !important;
        position: fixed !important;
        bottom: 7px !important;
        width: 93% !important;
        z-index: 1000 !important;
        color: #fff;
    }
}



.element,
.outer-container {
    width: 700px;
    height: 375px;
}

.outer-container {
    /* border: 5px solid rgb(170, 19, 19); */
    position: relative;
    overflow: hidden;
}

.inner-container {
    position: absolute;
    left: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.cdet {
    margin-top: 170px !important;
    padding-bottom: 5;
}

@media(max-width: 768px) {
    .cdets {
        display: none;
    }

    .cdetails {
        display: none;
    }

    /* .cdetails1{
        display: grid;
        text-align: center;
    } */
}

@media (max-width: 768px) {
    .cdetails1 {
        display: grid !important;
        text-align: center;
        margin-top: 70px !important;
    }

    
    /* .outer-container {
        margin-top: -140px !important;
    } */
}
@media (max-width: 500px){
     .outer-container {
        margin-top: -150px !important;
        max-width: 500px !important;
    }
    .rowhead{
        max-width: 630px !important;
    }
}

.cdets {
    margin-top: 220px !important;
    padding-bottom: 5;
    /* display: none; */
}

.cdetails1 {
    display: none;
}

.combocol{
    padding-bottom: 15px;
}

.mywishlistsdiv{
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    padding: 15px;
    padding-bottom: 15px;
    margin-bottom: 20px;
}
.my-wishlist{
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
}

@media (max-width: 991px){
    .orderImg{
        width: 100px !important;
    }
    .order-details{
        margin-top: 30px !important;
    }
    .order-deliver{
        margin-top: unset !important;
    }
}
.wishlist-rate{
    background-color: #2cd801;
    width: 50px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
}
.btn11{
    width: 50%;
    margin-top: 15px;
    height: 30px;
    padding-top: 2px !important;
    color: #00a085!important;
    background-color: #ffff!important;
    box-shadow: none !important;
    /* font-weight: bold; */
}
.prd3-currency{
    font-size: 18px;
    color: #00a085;
    font-weight: 500;
}

/* @media(min-width: 768px) and (max-width: 991px){
    .banner-img{
        height: 200px !important;
        
    }
    .slider{
        /* height: 132px !important; */
/* padding-top: 100px; */
/* margin-top: 80px;
        height:150px
    }  */

/* } */
@import url("https://fonts.googleapis.com/css2?family=Arizonia&family=Great+Vibes&display=swap");
.slide-content {
  position: absolute;
  top: 55%;
  left: 70%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  padding: 10px;
  border-radius: 5px;
}

.title {
  color: #fff;
  text-shadow: 0 0 10px #0e0811, 0 0 20px #04571a, 0 0 30px #0b1a74;
  font-family: "Great Vibes", cursive;
  font-size: 40px;
}
.banner-img {
  height: 100%;
  /* margin-top: 20px !important; */
  width: 100%;
}

@media (max-width: 767px) {
  .banner-img {
    height: 100%;
    /* margin-top: 40px !important; */
    width: 100%;
  }
  .slider {
    /* margin-top: 40px; */
    /* height: 120px; */
  }
  .slide-content {
    top: 70%;
    left: 70%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 5px;
    border-radius: 3px;
  }
  .title {
    font-size: 13px;
  }
  .carousel-control-prev,
  .carousel-control-next {
    opacity: 1;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    display: none !important;
  }

  .carousel-control-prev {
    left: 10px;
    /* top: 50%; */
  }

  .carousel-control-next {
    right: 10px;
    /* top: 80%; */
  }
  .carousel-indicators {
    width: 8px !important;
    height: 8px !important;
    /* margin: 0 4px !important; */
  }
}
@media (max-width: 575px) {
  .banner-img {
    /* margin-top: 39px; */
    /* margin-top: 19px; */

  }
}
@media (min-width: 992px) {
  .banner-img {
    /* style={{ marginTop: 39, height: '100%' }} */
    /* margin-top: 11px !important; */
    height: 100% !important;
    width: 100%;
  }
}
